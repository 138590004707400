.navigation {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: -100%;
	left: 0;
	background-color: rgba(3, 24, 39, 0.9);
	z-index: 0;
	transform: translate3d(0, 0, 0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	overflow: hidden;
}

.navigation:before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	width: 100%;
	height: 0;
	background: rgba(3, 24, 39, 0.7);
	border-radius: 100%;
	padding-bottom: 100%;
	transform: scale(0.04), translatey(9999px);
	overflow: hidden;
}

.open .navigation {
	top: 0;
}

.open .navigation:before {
	animation: menu-animation 0.8s ease-in-out forwards;
}

@keyframes menu-animation {
	0% {
		transform: scale(0.04) translatey(300%);
		opacity: 0;
	}
	40% {
		transform: scale(0.04) translatey(0);
		transition: ease-out;
	}
	60% {
		transform: scale(0.02) translatey(0);
		opacity: 1;
	}
	61% {
		transform: scale(0.04);
	}
	99.9% {
		height: 0;
		padding-bottom: 100%;
		border-radius: 100%;
	}
	100% {
		transform: scale(2);
		height: 100%;
		padding-bottom: 0;
		border-radius: 0;
	}
}

.menu {
	position: fixed;
	top: 55%;
	left: 40%;
	transform: translate(-50%, -50%);
	color: #fff;
	z-index: 2;
}

.menu li {
	opacity: 0;
	text-align: center;
	text-transform: uppercase;
	transform: translatey(30px);
	text-align: start;
}

.menu li:before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	left: auto;
	background: #fff;
	width: 0;
	height: 100%;
	overflow: hidden;
	transition: width 0.2s cubic-bezier(0.18, 0.45, 0.1, 0.98);
}

.menu li:hover:before {
	left: 0;
	right: auto;
}

.menu li:hover:after {
	opacity: 1;
	transform: translate(0, 0);
}

.open .menu li {
	opacity: 1;
	transform: translatey(0px);
	transition: opacity 1.1s cubic-bezier(0.18, 0.45, 0.1, 0.98),
		transform 0.7s cubic-bezier(0.18, 0.45, 0.1, 0.98);
}

.open .first-list li:nth-child(1) {
	transition-delay: 0.9s;
}

.open .first-list li:nth-child(2) {
	transition-delay: 1.1s;
}

.open .first-list li:nth-child(3) {
	transition-delay: 1.3s;
}

.open .second-list li:nth-child(1) {
	transition-delay: 1.4s;
}

.open .second-list li:nth-child(2) {
	transition-delay: 1.6s;
}

.open .second-list li:nth-child(3) {
	transition-delay: 2s;
}

.open .third-list li:nth-child(1) {
	transition-delay: 2s;
}

.open .third-list li:nth-child(2) {
	transition-delay: 2.1s;
}
