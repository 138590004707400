.slick-current .team-text {
  display: block;
}
.slick-current .single-team-div {
  opacity: 1 !important;
  transition: 0.2s;
}

.Typewriter__cursor {
  display: none!important;
}

.slick-track {
  height: 340px;
}

.slide-div {
  height: 340px !important;
}

.blog-section {
  display: grid;
  grid-template-columns: 57.5% 37.5%;
  grid-template-areas: "mainsection sidebar";
  grid-gap: 5%;
}

.blog-section .mainsection {
  min-width: 57.5%;
  grid-area: mainsection;
}

.blog-section .sidebar {
  min-width: 37.5%;
  grid-area: sidebar;
}

.blog-section .blog-excerpt {
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
}

.carousel-disabled {
  opacity: 0.5;
}

.rule {
  margin-left: 30rem;
}

.carousel-active {
  opacity: 1 !important;
}

.home-carousel p {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.contact:hover {
  font-size: 0;
  line-height: 0;
}

.contact:hover:before {
  content: attr(data-hover);
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;
}

.slider-arrow {
  transition: 0.5s;
}

.slider-arrow:hover {
  transform: scale(1.2);
  transition: 0.5s;
}

.aboutTextBlack {
  color: '#031827'
}

.clipDiv {
  clip-path: polygon(95% 50%, 100% 100%, 0 100%, 0 0, 100% 0);
}

.about-div1{
  width: 60.6%;
  padding: 60px 50px 60px 80px
}

.about-div2{
  width: 64.3%;
}

.about-div2 img {
  height: 550px
}

.home-announcement{
  min-height: 380px; 
  width: 500px; 
  max-width: 500px;
}

.team-header {
    margin: 0;
  }

@media screen and (max-width: 1023px){
    .about-div2{
    width: 100%;
  }

  .about-div1{
    width: 80%;
    margin: -5rem auto 0;
    padding: 43px 20px 20px;
  }
  .about-div2 img {
    height: 300px;
    width: 100%;
  }

  .home-announcement{
    min-height: 200px; 
    width: 83.3%;
    margin: 3rem auto 0;
  }
}

@media screen and (max-width: 767px){
  .team-header {
    margin: 18px 0 16px !important;
    font-size: 30px;
  }

  /* .slick-track, .slide-div, .single-team-div {
  height: 340px !important;
} */

.slider-arrow {
  width: 50px;
}

.small-text {
  font-size: 9px;
  font-weight: 300;
}
}


